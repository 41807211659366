import React from 'react'
import { Link, navigate } from 'gatsby'
import { setUser, isLoggedIn } from '../services/auth'
import { Auth } from 'aws-amplify'
import { Formik, Form, Field, ErrorMessage } from 'formik'

const Newpassword = () => {
  /* if (isLoggedIn()) navigate('/app/profile') */
  return (
    <div className="flex h-screen">
      	<div className="sm:w-1/3 xl:w-1/4 bg-white m-auto mt-28 p-8 pb-2 rounded shadow-2xl">
        	<div className="text-center text-2xl text-gray-800">Paswoord Instellen</div>
        		<ul><li><p>Gelieve de code die u werd toegestuurd per mail in te voeren in het veld Verificatiecode.</p></li></ul>
		        <Formik
		          initialValues={{ username: '',password: '', verificationcode: ''}}
		          validate={values => {
		            const errors = {}
		            if (!values.username) {
		              errors.username = 'Required'
		            } else if (
		              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.username)
		            ) {
		              errors.username = 'Invalid email address'
		            }
		            return errors
		          }}
		          onSubmit={async (values, { setSubmitting }) => {
		            try {
						await Auth.forgotPasswordSubmit(values.username, values.verificationcode, values.password)
						/*
						await Keychain.setInternetCredentials('auth', values.username, values.password)
						await Auth.signIn(values.username, values.password)
						*/
						navigate('/app/home')
		            } catch (err) {
		              console.log('error...: ', err);
		              alert(err.message);
		            }
		          }}
		        >
		          {({ isSubmitting }) => (
		            <Form>
		              <label
		                htmlFor="username"
		                className="block mt-6 text-gray-500 text-base"
		              >
		                Email
		                <Field
		                  type="email"
		                  name="username"
		                  className="block border-gray-300 border-2 rounded text-gray-700 text-sm w-full px-2 py-2"
		                />
		                <ErrorMessage
		                  name="username"
		                  component="div"
		                  className="text-red-500 text-sm"
		                />
		              </label>
		              <label
                    htmlFor="verificationcode"
                    className="block mt-6 text-gray-500 text-base"
                  >
                    Verificatie Code
                    <Field
                      type="text"
                      name="verificationcode"
                      className="block border-gray-300 border-2 rounded text-gray-700 text-sm w-full px-2 py-2"
                    />
                    <ErrorMessage
                      name="verificationcode"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </label>
		              <label
                    htmlFor="password"
                    className="block mt-6 text-gray-500 text-base"
                  >
                    Nieuw Paswoord
                    <Field
                      type="password"
                      name="password"
                      className="block border-gray-300 border-2 rounded text-gray-700 text-sm w-full px-2 py-2"
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </label>
		              <button
		                type="submit"
		                disabled={isSubmitting}
		                className="block w-full mb-10 mt-8  rounded px-6 py-1 border-gray-500 bg-green-500 hover:bg-green-700 text-white font-bold"
		              >
		                Paswoord Opnieuw instellen
		              </button>
		            </Form>
		          )}
		        </Formik>
		</div>
    </div>
  )
}

export default Newpassword