import React from 'react'
import { getUser } from '../services/auth'
import Layout from "../components/Layout/layout"

const Profile = () => (
  <>
  		
  		<div className="text md:w-2/6">
	  		<div class="flex flex-wrap -mx-3 mb-6 md:justify-center">
				<div class="md:w-1/3 min-w-full">
			    	<h1 className="title p-3">Uw Profiel</h1>
			    </div>
			    <div class="md:w-2/3 min-w-full pl-20">  
				    <ul>
				      <li>E-mail: {getUser().email}</li>
				    </ul>
				</div>
			</div>    
		</div>
  </>
)

export default Profile