import React from 'react'
import { Link } from 'gatsby'
import { useState, useEffect } from "react"
import { graphql } from "gatsby"

import { API, graphqlOperation, Storage } from "aws-amplify"
import awsExports from "../aws-exports"
import * as queries from "../graphql/queries"

const Home = ({ data }) => {
      	
  const [videos, setVideos] = useState([])
  
  /*
  useEffect(() => {
    // fetch the videos and set them to the state object
    fetchVideos().then(r => {
      const data = r?.data?.listVodAssets?.items
      setVideos(data)
    })
  }, [])
 */
 
 	useEffect(() => {
    onPageRendered();
  	}, []);

  	const onPageRendered = async () => {
    getVideos();
  	};

  	const getVideos = async () => {
    const getVideo = await API.graphql(graphqlOperation(queries.listVodAssets,{sort: {direction: 'asc',field: 'order'}}));
	const media = getVideo.data.listVodAssets.items
	
	const sortedArray = media.sort(function(a, b) {
	return a.order - b.order
	});

	//console.log(sortedArray);
	
	let arrayLength = media.length;
	for(let i = 0 ; i < arrayLength; i++) {
	
	const s3_audio_url = await Storage.get(media[i].mp3, { expires: 60 });
	const s3_image_url = await Storage.get(media[i].image_url, { expires: 60 });
 	
 	media[i].s3_audio_url = s3_audio_url
 	media[i].s3_image_url = s3_image_url	 	
	//console.log(val);
	}
	
	//console.log(media)
	setVideos(media)
 	};
 
 if(!videos.length){
	 	       var items = 
	 	       <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-amber-500">
	 		   <span className="text-xl inline-block mr-5 align-middle">
	 		   <i className="fas fa-bell" />
	 		   </span>
	 		   <span className="inline-block align-middle mr-8">
	 		   Je bent helaas nog geen betalend lid waardoor je geen toegang hebt tot alle videos. Gelieve te abonneren, dankje.
	 		   </span>
	 		   <button className="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-4 mr-6 outline-none focus:outline-none">
	 		   <span>×</span>
	 		   </button>
	 		   </div>
        }else{
        }
		
  return (
	    	<div class="container mx-auto px-400">
				<h1 className="title">Relaxatielessen</h1>
					
					<div class="grid justify-center">
				        {videos.map(video => (
					       (video.format) == 'audio'
					       
						   ? (	      
						   <Link class="pt-12" to={`/audio/${video.id}`}>{video.title} 
						   <img src={video.s3_image_url} alt={video.title} className="work-grid-item"></img>
						   </Link>
						   )
						   : 
						   (
							<Link to={`/video/${video.id}`}>{video.title}
							<img src={video.s3_image_url} alt={video.title} className="work-grid-item"></img>
							</Link>
							
						   )
						))}
			      	</div>    	
	    	</div>
  )
}

export default Home

export const query = graphql`
  query {
    allIndexJson {
      nodes {
        title
        description
        text
        subtitle
        image
        home_items {
          name
          description
          completed
          image
          dataitemid
          dataitemprice
          dataitemurl
          dataitemname
        }
      }
    }
  }
`